/*
Dark #3D3F25
Light green #8CC63F
*/

body {
    background-color: #ffffff !important;
    padding: 0px;
}

.body {
    margin-bottom: 2in;
}

@media print {
    @page {
        size: 11in 17in;
        margin: 0.25in;
    }

    body {
        padding: 0.1in;
        padding-top: 0.25in;
        padding-bottom: 0.25in;
    }

    .body {
        margin-bottom: 0px;
    }
}

abbr {
    text-underline-offset: 3px;
    text-decoration-color: #777777;
}

.MuiDivider-root {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
}

.denseContainer .MuiDivider-root {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
}

.MuiDivider-root.MuiDivider-vertical {
    margin-top: 2px !important;
    margin-bottom: 2px !important;

    margin-left: 18px;
    margin-right: 10px;
}

.denseContainer .MuiDivider-vertical {
    margin-left: 4px;
    margin-right: 10px;
}

a {
    color: #000000;
}

/* */

.react-icons {
    vertical-align: middle;
    padding-top: 4px;
    padding-bottom: 4px;
}

.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-disabled {
    background-color: #cfcfcf !important;
}

.MuiInputBase-root.Mui-disabled input {
    background-color: #cfcfcf !important;
}

/* */

/* */

.MuiButton-root.MuiButton-contained {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 0;
    border-radius: 32px;
    height: 0.5in;
}

.MuiButton-root.MuiButton-contained.Mui-disabled {
    color: black;
    background: #c0c0c0;
}

.MuiButton-root.MuiButton-containedPrimary {
    background: linear-gradient(90deg, #8FB954 0%, #D2D550 100%);

    color: black;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.9);
}

.MuiButton-root.MuiButton-containedSecondary {
    background: linear-gradient(90deg, #dddddd 0%, #c0c0c0 100%);

    box-shadow: 0 3px 5px 2px rgba(255, 105, 135, .3);

    color: black;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.9);
}

.MuiButton-root.MuiButton-outlined {
    padding-left: 21px;
    padding-right: 21px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 16px;

    border: 3px;
}

.MuiButton-root.MuiButton-outlinedPrimary {
    background-color: #fefefe !important;
    color: #333333 !important;
    border: 1px solid #f0f0f0;
    box-shadow: none !important;
}

.MuiButton-root.MuiButton-outlinedSecondary {
    background-color: #8CC63F !important;
    color: #333333 !important;
    border: 1px solid #f0f0f0;
    box-shadow: none !important;
}

.MuiButton-root.MuiButton-text {
    border: 1px solid #c0c0c0;
}

.MuiButton-root.Mui-disabled {
    background-color: #cfcfcf !important;
}

.MuiButton-root.buttonLargeSquare {
    border-color: #c0c0c0;
    border-radius: 10px;

    padding-top: 24px;
    padding-bottom: 24px;

    padding-left: 12px;
    padding-right: 12px;

    height: 100%;
    min-width: 150px;
}

.MuiButton-root.buttonLargeSquare .MuiTypography-button {
    text-transform: none;
    white-space: normal !important;
    max-width: 100px !important;
    text-align: center;

    margin-bottom: 6pt;
}

/* Workspace Selector: ButtonGroup style */

.headerWithMenuButton {
    margin-bottom: 24px;
}

.headerWithMenuButton .MuiButtonBase-root svg {
    font-size: medium !important;
}

.headerWithMenuButton .MuiTypography-root {
    margin-bottom: 0px !important;
}

.headerWithMenuButton .MuiButtonGroup-root {
    opacity: 0.3;
}

.headerWithMenuButton .MuiButtonGroup-root:hover {
    opacity: 1.0;
}

.headerWithMenuButton .MuiButtonGroup-root .MuiButton-root {
    height: 32px !important;
    width: 64px;
    box-shadow: none;
    padding: 12px !important;
    box-sizing: border-box;
    border: 1px solid #8FB954 !important;
}

/* */

.hiddenButton.MuiButtonBase-root {
    opacity: 0.3;
    border: none !important;
}

.hiddenButton.MuiButtonBase-root:hover {
    opacity: 1.0;
}

@media only screen and (max-width: 700px) {
    .hiddenButton.MuiButtonBase-root {
        opacity: 0.4;
    }
}

/* */

.MuiChip-root {
    font-weight: 550;
}

.MuiChip-colorDefault {}

.MuiChip-root .MuiChip-icon {
    margin-left: 10px !important;
}

.MuiChip-root .MuiChip-label {}

.MuiChip-root.MuiChip-colorSecondary {
    background-color: #8CC63F !important;
    border-color: #3D3F25 !important;
    color: black;
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.9);
}

/* */

.MuiBreadcrumbs-root {
    color: #888888 !important;
}

.MuiBreadcrumbs-root .MuiBreadcrumbs-ol {
    font-size: 12pt;
    font-weight: normal;
}

.MuiBreadcrumbs-root .MuiBreadcrumbs-ol .MuiBreadcrumbs-li a {
    color: #888888 !important;
    text-decoration: none !important;
    display: flex;
    justify-content: center;
    align-items: center;

    min-height: 1cm;
    min-width: 1cm;
}

.MuiBreadcrumbs-root .MuiBreadcrumbs-ol .MuiBreadcrumbs-li a:hover {
    text-decoration: underline !important;
}

@media only screen and (max-width: 700px) {
    .MuiBreadcrumbs-separator {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
}

.header {
    justify-content: center;
}

.header .MuiTypography-root.MuiTypography-h1 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

/* Dialog */

.MuiDialog-paperWidthSm {
    min-width: 40%;
    max-width: 80% !important;
}

.MuiDialog-paperFullWidth {
    margin: 16px !important;

    width: 100% !important;
    max-width: 2000px !important;

    min-height: 50%;
}

@media only screen and (max-width: 1600px) {
    .MuiDialog-paperFullWidth {
        margin: 4px !important;
    }
}

@media only screen and (max-width: 700px) {
    .MuiDialog-paperWidthSm {
        margin: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
    }

    .MuiDialog-paperFullWidth {
        margin: 0 !important;
        max-width: 100% !important;
        width: 100% !important;
    }
}

.MuiDialogTitle-root {
    background-color: #ffffff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
    color: #c0c0c0;
    padding-bottom: 0px !important;
}


.MuiDialogTitle-root.MuiTypography-root.MuiTypography-h6 {
    font-weight: normal;
}

.MuiDialogContent-root {
    background-color: #ffffff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
    padding-top: 16px !important;
}

@media only screen and (max-width: 700px) {
    .MuiDialogContent-root {
        padding: 20px 8px !important;
    }
}

.MuiDialogContentText-root {
    color: #333333 !important;
    font-weight: normal !important;
}

.MuiDialogActions-root {
    background-color: #ffffff;
    background-image: linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16));
    border-top: 1px solid #8CC63F !important;
}

.MuiDialogActions-root .MuiButton-textPrimary {
    color: #8CC63F;
}

.MuiDialogActions-root .MuiButton-textPrimary:hover {
    color: #ffffff;
}

/* */

.MuiTooltip-tooltip {
    font-size: larger !important;
}

/* */

fieldset {
    padding-top: 1rem;

    border-radius: 7px;
    border: 1px solid #e9e9e9 !important;
}

fieldset legend {
    padding-left: 1rem;
    padding-right: 1rem;

    font-size: 12pt;
    font-weight: 500;
}

/* */

.MuiPaper-root {
    box-shadow: none !important;
    border: 1px solid #e9e9e9 !important;
    background-color: #f6f6f6 !important;
}

.MuiAppBar-root {
    box-shadow: none !important;
}

.MuiToolbar-root h2 {
    /* text-shadow: 2px 2px 10px rgba(51, 51, 51, 0.5); */
}

/* */

.MuiTypography-root.MuiTypography-caption {
    font-size: smaller;
}

.denseContainer .MuiTypography-body1 {
    font-size: 8pt;
}

.MuiTypography-root.MuiTypography-h1 {
    font-size: x-large;
    font-weight: 500;
    margin-bottom: 24px !important;
    margin-right: 24px !important;
}

.denseContainer .MuiTypography-root.MuiTypography-h1 {
    font-size: large;
    font-weight: 500;
    margin-bottom: 10px !important;
    margin-right: 10px !important;
}

.MuiTypography-root.MuiTypography-h2 {}

.MuiTypography-root.MuiTypography-h3 {}

.MuiTypography-root.MuiTypography-h4 {
    font-size: 16pt;
    font-weight: bold;
    margin-bottom: 16px !important;
}

.MuiTypography-root.MuiTypography-h5 {
    font-size: 20pt;
    font-weight: 500;
    margin-bottom: 12px;
}

.denseContainer .MuiTypography-root.MuiTypography-h5 {
    font-size: 16pt;
    font-weight: 500;
    margin-bottom: 12px !important;
}

.MuiTypography-root.MuiTypography-h6 {
    font-size: 16pt;
    font-weight: 600;
}

/* Workspace Selector: Button style */

.headerWithMenuButton {
    margin-bottom: 24px;
    align-items: center;
}

.denseContainer .headerWithMenuButton {
    margin-bottom: 8px;
}

.headerWithMenuButton .MuiTypography-root.MuiTypography-h1 {
    margin-bottom: 0px !important;
}

.headerWithMenuButton .MuiTypography-root.MuiTypography-h5 {
    margin-bottom: 0px !important;
}

/* */

/* .MuiMenu-list {
    background-color: #e0e0e0 !important;
    color: #333333;
} */

.MuiMenu-paper {
    box-shadow: rgba(61, 63, 37, 0.5) 5px 5px 5px !important;
}

.MuiMenu-list .MuiMenuItem-root {
    margin-bottom: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.MuiMenu-list .MuiMenuItem-root:last-child {
    margin-bottom: 0px;
}


/* */

.MuiFormControl-fullWidth {
    max-width: 5in;
}

.MuiFormLabel-root {
    font-size: 13pt !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-formControl fieldset {
    color: black;
    border: none !important;
}

.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    background-color: #f6f6f6 !important;
    margin-top: 16px;
}

.MuiFormControl-root.noMargin .MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    margin-top: 0px;
}

.MuiFormControl-root.noMargin input {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}

.MuiOutlinedInput-root {
    border: 1px solid #f6f6f6 !important;
}

.MuiOutlinedInput-root:hover {
    border: 1px solid #e0e0e0 !important;
}

.MuiOutlinedInput-root.Mui-focused {
    border: 1px solid #555555 !important;
}

.MuiInputAdornment-root {
    opacity: 0.2;
}

.MuiInputAdornment-root:hover {
    opacity: 0.8;
}

.MuiInputAdornment-root.delete:hover .MuiButtonBase-root {
    background-color: #F75D59 !important;
}

/* */

.MuiDrawer-root .MuiDrawer-paper {
    background-color: #f6f6f6;
}

.MuiDrawer-root .MuiDrawer-paper .MuiStack-root.listInfoItem {
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
}

.MuiDrawer-root .MuiDrawer-paper .MuiList-root li {}

.MuiDrawer-root .MuiDrawer-paper .MuiList-root li .MuiTypography-root {
    overflow: clip;
}

.MuiDrawer-root .MuiDrawer-paper .MuiList-root li a {
    display: block;
    width: 100%;
    text-decoration: none;
}

.MuiDrawer-root hr {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.MuiList-root .MuiList-root div {
    padding-left: 1.5rem;
}


/* */

.MuiListItem-root {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.MuiListSubheader-root {
    background-color: #e0e0e0 !important;
}

/* */

.reportTable.gridded>.MuiGrid-item {
    border-right: 1px solid #c0c0c0;
    border-bottom: 1px solid #c0c0c0;
    padding-right: 4px;
}

.reportTable .MuiGrid-item {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.reportTable .headerCell {
    color: #c0c0c0;
    font-size: larger;
}

.reportTable .stripeCell {
    background-color: #f8f8f8;
}

/* */

.MuiTable-root.reportTable {}

.MuiTable-root.reportTable .MuiTableCell-root {
    padding-left: 8px !important;
    padding-right: 4px !important;

    padding-top: 8px !important;
    padding-bottom: 8px !important;

    vertical-align: middle;
}

.MuiTable-root.reportTable .MuiTableBody-root .MuiTableRow-root:nth-child(odd) {
    background-color: #f8f8f8;
}

.tablerow-light {
    background-color: #ffffff;
}

.tablerow-dark {
    background-color: #F0EFEB;
}

.tablerow-dark th {
    background-color: #f8f8f8;
    border-bottom: 1px #f0f0f0 solid;
}

.MuiTable-root.reportTable .MuiTableBody-root .MuiTableRow-root.separator {
    background-color: #e0e0e0;
}

.MuiTable-root.reportTable .MuiTableRow-root:nth-child(1) {}

.MuiTable-root.reportTable .MuiTableRow-root:last-child {
    border-top: 0px !important;
    border: none !important;
}

.MuiTable-root.reportTable .MuiTableBody-root .MuiTableCell-root {}

.MuiTable-root.reportTable .MuiTableRow-root:last-child .MuiTableCell-root {
    border-top: 0px !important;
    border: none !important;
}

/* Autocomplete */

.MuiAutocomplete-clearIndicator {
    background-color: #F75D59 !important;
    margin-right: 8px !important;
}

.MuiAutocomplete-root:hover .MuiAutocomplete-popupIndicator {
    background-color: #8CC63F !important;
}

.MuiAutocomplete-inputRoot {
    padding-left: 6px !important;
    padding-right: 24px !important;
}

.MuiAutocomplete-input {
    font-size: smaller !important;
    /* padding-left: 0px !important; */
    padding-right: 0px !important;
}


/* MUI-Data-Table */

.MUI-Data-Table .MuiPaper-root {
    border: none !important;
    background: none !important;
}

.MUI-Data-Table .MuiToolbar-root {
    padding-left: 0px !important;
    padding-right: 40px !important;
    min-height: 0px !important;
}

.MUI-Data-Table .MuiToolbar-root .MuiButtonBase-root {
    opacity: 0.5;
}

.MUI-Data-Table .MuiToolbar-root:hover .MuiButtonBase-root {
    opacity: 1.0;
}

.MUI-Data-Table .MuiToolbar-root .headerWithMenuButton {
    margin-bottom: 0px !important;
}

.MUI-Data-Table .MuiTableRow-root {
    min-height: 60px !important;
}

.MUI-Data-Table .MuiTableCell-root {
    border-bottom: none !important;
    font-weight: 500 !important;
    font-size: larger !important;
}

@media only screen and (min-width: 600px) {
    .MUI-Data-Table .MuiTableCell-root {
        font-weight: 500 !important;
        font-size: medium !important;
    }
}

.MUI-Data-Table .MuiTableCell-head .MuiButtonBase-root {
    font-weight: 400 !important;
    font-size: smaller !important;
}

.MUI-Data-Table td.MuiTableCell-root.MuiTableCell-body {
    height: 60px;
}

.MUI-Data-Table .MuiTableRow-root:nth-child(odd) {
    background-color: #f5f5f5;
}

.MUI-Data-Table .MuiTableRow-root:hover {
    background-color: rgba(128, 128, 0, 0.3) !important;
}

.MUI-Data-Table .MuiTableRow-root.MuiTableRow-footer:hover {
    background-color: white !important;
}

.MUI-Data-Table .MuiTableFooter-root tr {
    background: none !important;
    border: none !important;
    color: #c0c0c0 !important;
}

.MUI-Data-Table .MuiTableFooter-root .MuiTablePagination-root {
    color: #555555 !important;
}

/* Data Grid */

.MuiDataGrid-root {
    border: none !important;
}

.MuiDataGrid-main {
    background-color: white;
}

.MuiDataGrid-columnSeparator--sideRight {
    color: #bebebe !important;
}


.MuiDataGrid-cell {
    border-bottom: none !important;
    font-weight: 500 !important;
    font-size: larger !important;
}

@media only screen and (min-width: 600px) {
    .MuiDataGrid-cell {
        font-weight: 400;
        font-size: smaller;
    }
}

/* grid-column-group groups visually by differentiating background-color (green / blue) */

.MuiDataGrid-cell.grid-column-group-a {
    background-color: rgba(140, 198, 63, 0.1) !important;
}

.MuiDataGrid-cell.grid-column-group-a.first {
    border-left: 1px solid #bebebe !important;
}


.MuiDataGrid-cell.grid-column-group-b {
    background-color: rgba(63, 140, 198, 0.1) !important;
}

.MuiDataGrid-cell.grid-column-group-b.first {
    border-left: 1px solid #bebebe !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: 400 !important;
}

.MuiDataGrid-columnHeader {
    max-height: 56px !important;

    height: 32px !important;
    line-height: 24px !important;
    background-color: "#ffffff" !important;
}

.MuiDataGrid-columnHeaders {
    min-height: 32px !important;
    max-height: 56px !important;
    padding-bottom: 12px !important;

    height: 32px !important;
    line-height: 24px !important;
}

.MuiDataGrid-columnHeadersInner {
    background-color: white;
}

.MuiDataGrid-footerContainer {
    background-color: white;
    border: none !important;
    color: #c0c0c0 !important;
}

.MuiDataGrid-footerContainer .MuiTablePagination-root {
    color: #888888 !important;
}

.MuiDataGrid-row:nth-child(1) {
    border-top: none !important;
}

.MuiDataGrid-row:nth-child(odd) {
    background-color: #F0EFEB;
}

.MuiDataGrid-row.Mui-selected:nth-child(even) {
    background-color: rgba(140, 198, 63, 0.5) !important;
}

.MuiDataGrid-row.Mui-selected:nth-child(odd) {
    background-color: rgba(140, 198, 63, 0.3) !important;
}

/* */

.dev_jrt_daylight_components_form_slider_Slider__container {
    border-width: 1px;
    border-style: solid;
    border-color: #BEBEBE;
    border-radius: 5px;
    padding: 2px 4px 2px 2px;
    width: 100%;
    background-color: #FFFFFF;
}

.MuiSlider-root {
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    clip-path: inset(0 -2px 0 0 round 3px) !important;
    padding: 1px !important;
}

.MuiSlider-rail {
    display: none !important;
}

.MuiSlider-track {
    display: block !important;
    height: 20px !important;
    background-color: #83BC41 !important;
    border: none !important;
    border-radius: 3px !important;

    box-sizing: border-box !important;
}

.MuiSlider-thumb {
    width: 16px !important;
    height: 16px !important;
    margin-left: 2px !important;
    border-radius: 3px !important;
    background-color: #8CC63F !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px 0px !important;
    z-index: 100 !important;

    box-sizing: border-box !important;
}

.MuiSlider-thumb::after {
    box-sizing: border-box !important;
}

.MuiSlider-thumb::after {
    width: 20px !important;
    top: -2px;
    height: 20px !important;
    position: relative;
    z-index: -1;
    content: '';
    background-color: #83BC41;
    border-radius: 0 3px 3px 0 !important;
    display: block;
    box-shadow: none !important;
    left: -2px;

    box-sizing: border-box !important;
}

/* */

.flexWrapHeaderContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    column-gap: 6rem;
    row-gap: 1rem;
}

.flexWrapContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    column-gap: 2rem;
    row-gap: 1rem;
}

.flexWrapContainer>.MuiBox-root.borderBox {
    padding-right: 1rem;
    border-right: 1px solid #c0c0c0;
}

.farm-event-drawer {
    backdrop-filter: blur(8px);
}

.farm-event-drawer .MuiDrawer-paperAnchorRight {
    margin: 20px;
    border-radius: 10px;
    height: calc(100% - 40px);
}

/* width */
.farm-event-drawer .MuiDrawer-paperAnchorRight::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
.farm-event-drawer .MuiDrawer-paperAnchorRight::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 25px;
}

/* Handle on hover */
.farm-event-drawer .MuiDrawer-paperAnchorRight::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.farm-event-drawer .drawer-close-btn {
    cursor: pointer;
    position: absolute !important;
    right: 10px;
}

.farm-event-drawer .headerWithMenuButton {
    margin-bottom: 0px;
    padding: 15px;
}

.trade_date div {
    padding-top: 0px !important;
    overflow: visible !important;
}

.trade_date_picker {
    width: 100% !important;
}

.MuiDataGrid-columnHeader--moving {
    background-color: #fff !important;
}

/* Transaction Import */

.transaction-import-workspace-table tr th {
    line-height: 1;
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 16px;
}

.transaction-import-workspace-table tr td {
    line-height: 1;
    padding: 8px;
}

/* Marketing Risk Summary*/

.marketing-table {
    border-collapse: collapse;
    border: 1px solid rgba(241, 248, 231, 1);
}

.marketing-table.marketing-table-second {
    margin-top: 50px;
}

.marketing-table.marketing-table-second {
    margin-top: 40px;
}

.marketing-position .position-print-btn {
    position: absolute !important;
    right: 10px;
}

.marketing-position .data-form .autoComplete div div {
    margin-top: 0px !important;
    border-radius: 12px !important;
    padding: 3px !important;
}

.marketing-position .data-form .autoComplete div label {
    top: -7px !important;
    font-size: 0.75rem !important;
    color: #333333 !important;
    text-transform: uppercase !important;
}

.marketing-position .data-form {
    max-width: 20% !important;
}

.clientState .MuiInputBase-input {
    font-weight: 700;
}

.brokerDataGrid .MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
    margin-top: 0px !important;
}

.enableMFA {
    background-color: #fff !important;
}

/* Online Help Content */

.online-help-drawer {}


.online-help-drawer p {
    padding-bottom: 16pt !important;
    line-height: normal !important;
}

.online-help-drawer-desc {
    padding: 15px;
}

.online-help-drawer-box {
    width: 20%;
}

.online-help-drawer .css-egb3g5-MuiPaper-root-MuiCard-root {
    height: 100%;
    border-radius: 0px !important;
    border: none !important;
    min-width: 500px;
}

@media only screen and (max-width: 500px) {
    .online-help-drawer .css-egb3g5-MuiPaper-root-MuiCard-root {
        min-width: 100vw;
    }
}



.online-help-drawer .drawer-header {
    border-radius: 0px !important;
}

.online-help-drawer .MuiCardContent-root.css-46bh2p-MuiCardContent-root {
    overflow-x: auto;
}

/* --- */

.video-container.fixed-video {
    position: fixed;
    bottom: 220px;
    right: 20px;
    width: 450px;
    height: 170px;
    z-index: 1000;
    transform: translate(0, 0);
    transition: all 0.5s ease-in-out;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    /* Fade in/out transition */
    opacity: 1;
    visibility: visible;
}

.video-container.slide-right {
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
}

.video-container .video-icons {
    position: absolute;
    top: 5px;
    font-size: 30px;
}

.video-container .close-icon {
    right: -15px;
    cursor: pointer;
}

.video-container .drag-icon {
    left: -15px;
    cursor: grabbing;
}

.cash-sales-position-summary {
    display: flex;
    margin-bottom: 20px;
}

.cash-sales-position-summary .add-sale {
    position: absolute;
    right: 10px;
}

.copy-content {
    cursor: pointer;
}

.riskSummaryAccordion .MuiAccordionDetails-root {
    padding: 0 !important;
}

.riskSummaryAccordion .MuiDataGrid-columnHeaders {
    min-height: 56px !important;
    padding-bottom: 0 !important;
}

.enableMFAGrid {
    margin-top: 50px;
}

.changeMFADialog .enableMFAGrid {
    margin-top: 0px !important;
}

.Tilley-DataGrid-StickyHeader {
    position: relative;
    /* margin: 20px;
    border: 1px solid #ccc; */
}

/* Sticky DataGrid Header */
.data-grid.sticky-header .MuiDataGrid-columnHeaders {
    position: fixed;
    top: 0;
    left: 300px;
    right: 0;
    z-index: 1000;
    background: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 25px !important;
}

@media only screen and (max-width: 767px) {
    .flexWrapContainer {
        display: grid;

        grid-column-gap: 1rem;
        grid-row-gap: 1rem;

        grid-template-columns: repeat(auto-fit, minmax(30px, 45%));
        width: 100%;

        justify-content: start;
    }

    .farm-event-drawer .MuiDrawer-paperAnchorRight {
        margin: 0;
        margin-top: 15px;
        height: calc(100% - 30px);
    }

    .farm-event-drawer .MuiDrawer-paperAnchorRight .css-14wr5jn-MuiTypography-root-MuiTimelineOppositeContent-root,
    .farm-event-drawer .MuiDrawer-paperAnchorRight .css-1yb1l1b-MuiTypography-root-MuiTimelineContent-root {
        padding: 0 5px !important;
    }

    .headerWithMenuButton {
        justify-content: space-between;
    }

    .marketing-position {
        display: block !important;
    }

    .marketing-position .broker-filter {
        margin-left: 0px !important;
        margin-top: 10px !important;
    }

    .marketing-position .year-filter,
    .marketing-position .position-print-btn {
        margin-top: 10px !important;
    }

    .marketing-position .break-line {
        margin-left: 0 !important;
    }
}

/* */

.cardContainer {
    display: grid;

    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

    grid-template-columns: repeat(auto-fit, minmax(350px, 500px));
    width: 100%;

    justify-content: start;
}

.cardContainer .MuiCard-root {
    flex: 1;
    max-width: 500px;
    min-width: 380px;
}

@media only screen and (min-width: 1025px) and (max-width: 1440px) {
    .cardContainer {
        grid-template-columns: repeat(auto-fit, minmax(380px, 490px));
        width: 100%;

        grid-column-gap: 12px;
        grid-row-gap: 1rem;
    }

    .cardContainer .MuiCard-root {
        max-width: 540px;
        min-width: 380px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .cardContainer {
        grid-template-columns: repeat(auto-fit, minmax(380px, 480px));
        width: 100%;

        grid-column-gap: 12px;
        grid-row-gap: 1rem;
    }

    .cardContainer .MuiCard-root {
        max-width: 490px;
        min-width: 380px;
    }
}

@media only screen and (max-width: 425px) and (max-width: 768px) {
    .cardContainer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        gap: 1rem;

        max-width: 100%;
        align-items: stretch;
        align-content: stretch;
    }

    .cardContainer .MuiCard-root {
        max-width: 100%;
        min-width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .cardContainer .MuiCard-root {
        max-width: 100%;
    }
}

.MuiCard-root {
    box-shadow: rgba(61, 63, 37, 0.3) 3px 3px 3px !important;
    margin-bottom: 5px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.MuiCard-root.MuiPaper-root:hover {
    background-color: #eeeeee !important;
    box-shadow: rgba(61, 63, 37, 0.5) 3px 3px 3px !important;
}

.MuiCard-root.empty.MuiPaper-root:hover {
    background-color: #f6f6f6 !important;
    box-shadow: rgba(61, 63, 37, 0.3) 3px 3px 3px !important;
}

.MuiCardHeader-root {
    padding-bottom: 0px !important;
}

.MuiCardHeader-root .MuiCardHeader-content .MuiTypography-root.MuiCardHeader-title {
    font-size: large;
}

.MuiCardHeader-root .MuiCardHeader-content .MuiTypography-root.MuiCardHeader-subheader {
    font-size: medium;
}

.MuiCardActions-root {
    padding-top: 0px !important;
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-bottom: 16px !important;

    justify-content: flex-start;
}

.MuiCardActions-root button:last-child {
    margin-left: auto;
    margin-top: auto;
}

.MuiCardContent-root {
    height: 100%;
}

.MuiCardContent-root .MuiTypography-root.MuiTypography-caption {
    font-size: 10pt;
    line-height: 11pt;
    margin-bottom: 0px !important;
    height: auto !important;
}

.MuiCardContent-root .MuiTypography-root.MuiTypography-h6 {
    white-space: nowrap;
}

.MuiCardContent-root .MuiTypography-root.MuiTypography-body1 {
    color: #888888 !important;
    font-size: 12pt;
    line-height: 13pt;
    margin-bottom: 0px !important;
    height: auto !important;
}

.MuiCardActions-root .MuiTypography-root.MuiTypography-h6 {
    white-space: nowrap;
}

.MuiCardActions-root .MuiTypography-root.MuiTypography-body1 {
    color: #888888 !important;
    font-size: 12pt;
    line-height: 13pt;
    margin-bottom: 0px !important;
    height: auto !important;

    white-space: nowrap;
}

.MuiCardActions-root .flexWrapContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    column-gap: 0.5rem;
    row-gap: 0.5rem;
}

/* */

.MuiStepIcon-root.Mui-completed {
    color: #000000 !important;
}

.MuiStepIcon-root.Mui-active {
    color: #8CC63F !important;
}

/* */

.valueLabel {}

.valueLabel .MuiTypography-body1 {
    color: #999999;
}

.valueLabel .MuiTypography-body2 {
    color: #000000;
    font-size: x-large !important;
    font-weight: normal !important;
}

/* */

#form-file-upload {
    height: 16rem;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
}

#input-file-upload {
    display: none;
}

#label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border-style: dashed;
    border-color: #cbd5e1;
    background-color: #f8fafc;
}

#label-file-upload.drag-active {
    background-color: #f8f8f8;
}

.upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: 'Oswald', sans-serif;
    background-color: transparent;
}

.upload-button:hover {
    text-decoration-line: underline;
}

#drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

/* */

.insuranceOptimizeDataGrid .MuiTableCell-root {
    white-space: nowrap;
}

/* */

@media print {
    .navigation {
        display: none !important;
    }

    .MuiCardHeader-action {
        display: none !important;
    }

    .MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl {
        background: none;
        background-color: transparent !important;
        border: none !important;
        border-bottom: 1px solid #efefef !important;
        border-radius: 0 !important;
    }

    .MuiCard-root {
        box-shadow: none !important;
    }

    .MuiPaper-root {
        box-shadow: none !important;
        border: 1px solid #e9e9e9 !important;
        background-color: transparent !important;
    }

    .MuiInputAdornment-root {
        display: none !important;
    }

    .MuiAutocomplete-endAdornment {
        display: none !important;
    }

    .MuiInputBase-root svg {
        display: none !important;
    }
}

.pageBreakAvoid {
    page-break-inside: avoid;
}

.pageBreakBefore {
    page-break-before: always;
}

.pageBreakAfter {
    page-break-after: always;
}

/* */
/* linearGauge */

.linearGauge .MuiLinearProgress-root {
    height: 1rem;
    border-radius: 10px;
    border: 1px solid black !important;
}

.linearIndicator {
    padding: 4px;
    border-radius: 7px;
}

.linearIndicator:hover {
    background-color: #fbfbfb;
}

.linearIndicator .indicator {
    height: 1rem;
    border-radius: 10px;
    background-color: #c0c0c0;
    opacity: 0.5;
}

.linearIndicator .indicator.low {
    background-color: #D61F1F;
    opacity: 1;
}

.linearIndicator .indicator.medium {
    background-color: #FFD301;
    opacity: 1;
}

.linearIndicator .indicator.high {
    background-color: #7BB662;
    opacity: 1;
}

.linearIndicator .MuiTypography-root {
    white-space: pre;
    opacity: 0.5;
    padding-top: 0px;
    padding-bottom: 0px;
    line-height: 1;
}


.linearIndicator .MuiTypography-root.checkpoint {
    margin-top: 5px;
    align-items: center;
}

.linearIndicator .MuiTypography-root.title {
    opacity: 1;
    font-size: 1.1rem;
    font-weight: normal;
    padding-bottom: 4px;
}


.linearIndicator .MuiTypography-root.value {
    opacity: 1;
    font-size: 1rem;
}

.linearIndicator:hover .indicator {
    opacity: 1;
}

.linearIndicator:hover .MuiTypography-root {
    opacity: 1;
}

/* VegaEmbed */

.vg-tooltip {
    z-index: 99999 !important;
}

/* formStatusAlert */

.formStatusAlert.MuiAlert-root {
    margin-right: 0px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 2px;
    padding-bottom: 2px;
}

.formStatusAlert.MuiAlert-root .MuiAlert-icon {
    margin-right: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.formStatusAlert.MuiAlert-root .MuiAlert-message {
    display: none;
    margin-left: 12px;
    padding-top: 2px;
    padding-bottom: 0px;
}

.formStatusAlert.MuiAlert-root:hover .MuiAlert-message {
    display: block;
    margin-left: 12px;
}

/* --- */

[data-amplify-container] {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
}

[data-amplify-router] {
    border: none !important;
    box-shadow: none !important;
}

fieldset.amplify-flex {
    border: none !important;
}


/* Syncfusion Grid Components */
.e-grid.tilley .e-groupdroparea {
    background-color: #3D3F2588;
}

.e-grid.tilley-grid-summary .e-recordplusexpand .e-icons {
    display: none;
}

.cardContainer .MuiCard-root {
    cursor: pointer;
}

.css-1msha2m-MuiStepLabel-label.Mui-active {
    font-weight: 700 !important;
}

.css-vnkopk-MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel.Mui-active .css-havevq-MuiSvgIcon-root {
    fill: #00a8e8 !important;
}

.groupItems label {
    cursor: pointer;
}

.chatButton {
    width: fit-content;
}

.MuiButtonBase-root.MuiListItemButton-root.active {
    background: linear-gradient(90deg, #ffffff55 0%, #8CC63F55 10%) !important;
}

.MuiTimelineOppositeContent-root {
    opacity: 0.33;
}

.MuiTimelineItem-root:hover .MuiTimelineOppositeContent-root {
    opacity: 1.00;
}

/* React-leaflet */
.icon {
    font-family: "Georgia, Serif";
    border: none;
    background: transparent;
    color: #00000088;
    text-shadow: 0 0 3px #ffffffaa;
    font-size: larger;
}

.marketing-table {
    border-collapse: collapse;
    border: 1px solid rgba(241, 248, 231, 1);
}

.marketing-table.marketing-table-second {
    margin-top: 50px;
}

.marketing-table.marketing-table-second {
    margin-top: 40px;
}

.workspaceActionBar {
    z-index: 1000;
}